export type ListenerFn<T> = (state: T) => void;

export type UnsubscribeFn = () => void;

export default function createStore<T>(initialState: T) {
    let state: T = initialState;
    let listeners: ListenerFn<T>[] = [];
    return {
        // Get the state
        get(): T {
            return state;
        },

        // Change the state
        update(newState: T) {
            state = newState;
            listeners.forEach(l => l(state));
        },

        // Get notified when something changes
        subscribe(callback: ListenerFn<T>): UnsubscribeFn {
            listeners.push(callback);
            callback(state);
            return () => {
                const index = listeners.indexOf(callback);
                if(index !== -1) {
                    listeners = listeners.splice(index, 1);
                }
            };
        }
    }
}