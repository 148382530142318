export interface InkPoint {
    x: number;
    y: number;
    pressure?: number;
    timestamp: number;
}

export type Color = [number, number, number, number];

export interface InkStroke {
    points: InkPoint[];
    color: Color;
}

export function getDateKey(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
}