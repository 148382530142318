import { LitElement, html, css, property, customElement } from "lit-element";
import Cell, { cellToIndex } from "../crossword/Cell";
import { ExtendedCrossword } from "../crossword/ClueMap";

import { CWCanvas, RecognitionResult } from "./cw-canvas";
import "./cw-canvas";

@customElement("cw-cell")
export class CWCell extends LitElement {
    @property() cell!: Cell;
    @property() crossword!: ExtendedCrossword;

    // Theming
    @property() value: string = "";
    @property() clue: string | null = null;
    actual!: string;

    // States
    @property({type: Boolean}) selected: boolean = false;
    @property({type: Boolean}) showErrors: boolean = false;
    @property({type: Boolean}) isWall: boolean = false;

    static get styles() {
        return css`
            :host {
                flex-shrink: 0;
                flex-grow: 0;
                position: relative;
                width: 40px;
                height: 40px;
                border: 1px solid #000;
                background-color: #FFF;
                background-color: #FFF;
            }
            .wall {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: #000;
            }
            .clue {
                position: absolute;
                top: 4px;
                left: 4px;
                font-size: 11px;
                color: rgba(0,0,0,.54);
                pointer-events: none;
            }
            .value {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: rgba(0,0,0,.86);
                pointer-events: none;
            }
        `;
    }

    get index(): number {
        return cellToIndex(this.crossword, this.cell);
    }

    private setValue(value: string) {
        if(value === this.value) {
            return;
        }

        this.value = value;
        if(value) {
            const ev = new CustomEvent<string>("cell-change", {
                detail: value,
            });
            this.dispatchEvent(ev);
        } else {
            const ev = new CustomEvent("cell-clear");
            this.dispatchEvent(ev);
        }
    }

    private handleRecognition(e: CustomEvent<RecognitionResult>) {
        (e.target as CWCanvas).clear();

        // Check if we scribbled out an existing value
        if(this.value !== "" && e.detail.gesture_result.length >= 1 && e.detail.gesture_result[0] === "SCRIBBLE_OUT") {
            this.setValue("");
        }

        const recognitions = e.detail.result;
        const valid = recognitions
            .slice(0, 2)
            .filter(r => r.length === 1)
            .map(r => r.toLocaleUpperCase())
            .filter(r => /[A-Z]/.test(r));
        
        if(recognitions[0].length === 1 && recognitions[0] === "?") {
            this.setValue(this.actual);
        } else if(valid.length > 0) {
            this.setValue(valid[0]);
        } else {
            this.setValue("");
        }
    }

    private handleClick() {
        const ev = new CustomEvent("cell-click");
        this.dispatchEvent(ev);
    }

    render() {
        // Is a wall
        if(this.isWall) {
            return html`<div class="wall"></div>`;
        }

        // Get background color
        let background = "#FFF"; // white
        if(this.showErrors && this.value !== "" && this.value !== this.actual) {
            background = "#EA4335"; // red
        } else if(this.selected) {
            background = "#FEEFC3"; // yellow
        }

        // TODO: circles, etc

        return html`
            <cw-canvas
                width="40" height="40"
                .backgroundColor="${background}"
                @canvas-click="${() => this.handleClick()}"
                @canvas-recognition="${(e: CustomEvent<RecognitionResult>) => this.handleRecognition(e)}">
            </cw-canvas>
            ${this.clue && html`<div class="clue">${this.clue}</div>`}
            ${this.value && html`<div class="value">${this.value}</div>`}
        `;
    }
}