import Selection from "./crossword/Selection";
import GuessMap from "./crossword/GuessMap";
import { ExtendedCrossword, extendCrossword } from "./crossword/ClueMap";
import { getCrossword, getClue, isCompleted, isFilledIn } from "./crossword/utils";
import createStore from "./store";

import * as Storage from "./storage";

export type Page = 
    | {name: "main"}
    | {name: "crossword", date: string};

export type Loadable<T> =
    | {state: "loading"}
    | {state: "error"}
    | {state: "success", value: T};

export interface CrosswordState {
    date: string;
    selection: Selection;
    guesses: GuessMap;
    isDone?: boolean;
}

export type MessageType = "CrosswordClose" | "CrosswordComplete";
export interface MessageState {
    visible: boolean;
    type: MessageType;
}
export interface AppState {
    page: Page;

    message: MessageState;

    // Settings
    showHints: boolean;

    // Crossword state
    crosswords: {
        [date: string]: Loadable<ExtendedCrossword>;
    };
    progress: {
        [date: string]: CrosswordState;
    };
}

export const stateManager = createStore<AppState>({
    page: {name: "main"},
    message: {visible: false, type: "CrosswordClose"},
    showHints: false,
    crosswords: {},
    progress: {},
});

export function navigateTo(page: Page) {
    const state = stateManager.get();
    if(page.name === "crossword") {
        const defaultProgress: CrosswordState = {
            date: page.date,
            selection: {direction: "across", clue: 1},
            guesses: {},
        };
        const progress: CrosswordState = (page.date in state.progress)
            ? state.progress[page.date]
            : defaultProgress;
        stateManager.update({
            ...state,
            page,
            progress: {
                ...state.progress,
                [page.date]: progress,
            },
        });
    } else {
        stateManager.update({...state, page});
    }
}

export function loadCrossword(date: string) {
    const state = stateManager.get();

    // Only load if not already in-progress or done
    if(date in state.crosswords && state.crosswords[date].state !== "error") {
        return;
    }

    function setCrosswordState(cwState: Loadable<ExtendedCrossword>) {
        const state = stateManager.get();
        stateManager.update({
            ...state,
            crosswords: {
                ...state.crosswords,
                [date]: cwState,
            }
        })
    }

    setCrosswordState({state: "loading"});

    const parts = date.split("-");
    console.assert(parts.length === 3);
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);

    getCrossword(year, month, day)
        .then(crossword => {
            setCrosswordState({
                state: "success",
                value: extendCrossword(crossword),
            });
        }).catch(err => {
            setCrosswordState({state: "error"});
        });
}

export function loadProgress() {
    Storage.getAll().then(cwStates => {
        const state = stateManager.get();
        const newProgress = {...state.progress};
        for(let cwState of cwStates) {
            newProgress[cwState.date] = cwState;
        }
        stateManager.update({
            ...state,
            progress: newProgress,
        });
    });
}

export function setSelection(selection: Selection) {
    const state = stateManager.get();
    if(state.page.name !== "crossword") {
        return;
    }

    const date = state.page.date;
    console.assert(date in state.progress);
    const newProgress = {
        ...state.progress[date],
        selection,
    }
    stateManager.update({
        ...state,
        progress: {
            ...state.progress,
            [date]: newProgress,
        },
    });
    Storage.update(newProgress);
}

export function setGuesses(guesses: GuessMap) {
    const state = stateManager.get();
    if(state.page.name !== "crossword") {
        return;
    }

    const date = state.page.date;
    console.assert(date in state.progress);
    
    // Check if crossword is done
    const cw = getCurrentCrossword(state);
    const wasDone = state.progress[date].isDone || false;
    let isDone = wasDone;
    if(cw && cw.state === "success") {
        isDone = isCompleted(cw.value, guesses);
    }

    // Filled in
    let wasFilled = false;
    let isFilled = false;
    if(cw && cw.state === "success") {
        wasFilled = isFilledIn(cw.value, state.progress[date].guesses);
        isFilled = isFilledIn(cw.value, guesses);
    }

    let message = state.message;
    if(isDone && !wasDone) {
        message = {
            visible: true,
            type: "CrosswordComplete",
        };
    } else if(isFilled && !wasFilled) {
        message = {
            visible: true,
            type: "CrosswordClose",
        };
    }

    const newProgress = {
        ...state.progress[date],
        guesses,
        isDone,
    }
    stateManager.update({
        ...state,
        message: message,
        progress: {
            ...state.progress,
            [date]: newProgress,
        },
    });
    Storage.update(newProgress);
}

export function setMessageVisibility(visible: boolean) {
    const state = stateManager.get();
    stateManager.update({
        ...state,
        message: {
            ...state.message,
            visible,
        },
    });
}

export function setHints(showHints: boolean) {
    const state = stateManager.get();
    stateManager.update({
        ...state,
        showHints,
    });
}

// Selectors
// TODO: memoize results

export function getCurrentCrossword(state: AppState): Loadable<ExtendedCrossword> | null {
    if(state.page.name !== "crossword") {
        return null;
    }
    const date = state.page.date;
    if(!(date in state.crosswords)) {
        return null;
    }
    return state.crosswords[date];
}

export function getCurrentProgress(state: AppState): CrosswordState {
    if(state.page.name !== "crossword") {
        return {
            date: "",
            selection: {direction: "across", clue: 1},
            guesses: {},
        }
    }
    const date = state.page.date;
    if(!(date in state.progress)) {
        return {
            date,
            selection: {direction: "across", clue: 1},
            guesses: {},
        };
    }
    return state.progress[date];
}

export function getTitle(state: AppState): string {
    if(state.page.name === "main") {
        return "Crosswords";
    }
    if(state.page.name === "crossword") {
        let crossword = getCurrentCrossword(state);
        if(!crossword) {
            return "Loading...";
        }
        if(crossword.state === "loading") {
            return "Loading...";
        }
        if(crossword.state === "error") {
            return "Crossword not found";
        }
        if(crossword.state === "success" && state.page.date in state.progress) {
            const sel = state.progress[state.page.date].selection;
            return getClue(crossword.value, sel);
        }
    }
    return "Page not found";
}
