const LANGUAGE = "en";
const GESTURE_LANGUAGE = "gesture_in_context_tflite";

// Request

/* x, y, timestamp, pressure */
export type Stroke = [number[], number[], number[], number[]];

interface WritingGuide {
	writing_area_width: number;
	writing_area_height: number;
}

interface RecognitionRequest {
	ink: Stroke[];
	writing_guide?: WritingGuide;
	language: string;
}

interface HandwritingRequest {
	app_version: number;
	options?: string;
	requests: RecognitionRequest[];
}

// Response

export type HandwritingResponse = [string, RecognitionResponse[], string, object];
export type RecognitionResponse = [string, string[], Array<any>, RecognitionData];

export type StrokeSegment = [number, number, number, number];
export type CharacterResultSegmentation = [string, StrokeSegment[]];
export type RecognitionResultSegmentation = CharacterResultSegmentation[];
export interface RecognitionData {
	segmentation?: RecognitionResultSegmentation[]
}

export class ResponseWrapper {
	private resp: HandwritingResponse;
	constructor(resp: HandwritingResponse) {
		this.resp = resp;
	}
	get status(): "SUCCESS" | "FAILURE" {
		return this.resp[0] as any;
	}
	get results(): string[] {
         	let resps = this.resp[1];
                // should have lang + gesture
		if(resps.length < 2) {
			return [];
		}
		let topResponse = resps[0];
		return topResponse[1];
	}
        get gesture(): string[] {
 		let resps = this.resp[1];
                // should have lang + gesture
		if(resps.length < 2) {
			return [];
		}
		let topResponse = resps[1];
		return topResponse[1];
        }
}

export function recognize(strokes: Stroke[], width: number, height: number): Promise<ResponseWrapper> {
	let req = createRecognitionRequest(strokes, {
		writing_area_width: width,
		writing_area_height: height,
	});
	return runRequest(req).then(resp => new ResponseWrapper(resp));
}

function createRecognitionRequest(strokes: Stroke[], writingGuide: WritingGuide): HandwritingRequest {
  let minx = 100;
  let miny = 100;
  let maxx = -100;
  let maxy = -100;
  for (const stroke of strokes) {
    for (const pt of stroke[0]) {
      minx = Math.min(minx, pt);
      maxx = Math.max(maxx, pt);
    }
    for (const pt of stroke[1]) {
      miny = Math.min(miny, pt);
      maxy = Math.max(maxy, pt);
    }
  }
  let w = (maxx-minx)/4;
  let h = (maxy-miny)/4;
  minx += w;
  maxx -= w;
  miny += h;
  maxy -= h;
  let gesture_strokes = [];
  for (let coords of [[minx, miny, minx, maxy],
                      [minx, maxy, maxx, maxy],
                      [maxx, maxy, maxx, miny],
                      [maxx, miny, minx, miny]]) {
    let ln: Stroke = [[], [], [], []];
    let wd = coords[2] - coords[0];
    let hd = coords[3] - coords[1];
    for (let i = 0; i < 30; ++i) {
      ln[0].push(wd/30.0 * i +  coords[0]);
      ln[1].push(hd/30.0 * i +  coords[1]);
      ln[2].push(0);
      ln[3].push(1);
    }
    gesture_strokes.push(ln);
}
gesture_strokes.push.apply(gesture_strokes, strokes.slice());
    return {
		app_version: 1,
		requests: [{
			ink: strokes,
			writing_guide: writingGuide,
			language: LANGUAGE,
		}, {
   			ink: gesture_strokes,
			//  writing_guide: writingGuide,
			language: GESTURE_LANGUAGE,
		}],
	};
}

function runRequest(req: HandwritingRequest): Promise<HandwritingResponse> {
	let headers = new Headers({"Content-Type": "application/json"});
	let p = fetch(new Request("https://inputtools.google.com/request?ime=handwriting&app=tbuckley&dbg=1&cs=1&oe=UTF-8", {
		method: "POST",
		body: JSON.stringify(req),
		headers: headers,
	})).then((resp) => {
		return resp.json();
	});
	return (p as Promise<any>);
}
