import Dexie from "dexie";
import { CrosswordState } from "./model";

const DB_NAME = "crosswords";
const STATE_TABLE = "crossword_state";

const db = new Dexie(DB_NAME);

db.version(1).stores({
    [STATE_TABLE]: "&date",
});

const getStateTable = (): Dexie.Table<CrosswordState, string> => (db as any)[STATE_TABLE];

export function getAll(): Promise<CrosswordState[]> {
    return getStateTable().toArray();
}

export function update(state: CrosswordState): Promise<string> {
    const table = getStateTable();
    return table.put(state);
}