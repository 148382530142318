import { LitElement, html, customElement, query, css, property } from "lit-element";
import { InkStroke, InkPoint } from "../common";
import * as HWR from "../hwr";

interface ChromePointerEvent extends PointerEvent {
    getCoalescedEvents(): PointerEvent[];
}

export interface RecognitionResult {
    result: string[];
    gesture_result: string[];
}
function recognize(strokes: InkStroke[]): Promise<RecognitionResult> {
    const recoStrokes: HWR.Stroke[] = [];
    strokes.forEach(stroke => {
        const recoStroke: HWR.Stroke = [[],[],[],[]];
        stroke.points.forEach(p => {
            recoStroke[0].push(p.x);
            recoStroke[1].push(p.y);
            recoStroke[2].push(p.timestamp);
            recoStroke[3].push(1);
        });
        recoStrokes.push(recoStroke);
    });
    return HWR.recognize(recoStrokes, 300, 300).then((resp) => {
        return {
            result: resp.results,
            gesture_result: resp.gesture,
        };
    });
}

function drawSegment(ctx: CanvasRenderingContext2D, a: InkPoint, b: InkPoint) {
    ctx.beginPath();
    ctx.moveTo(a.x, a.y);
    ctx.lineTo(b.x, b.y);
    ctx.stroke();
}

function drawStroke(ctx: CanvasRenderingContext2D, stroke: InkStroke) {
    if(stroke.points.length < 2) {return;}
    
    const [r,g,b,a] = stroke.color;
    ctx.strokeStyle = `rgba(${r},${g},${b},${a})`;
    
    for(let i = 0; i < stroke.points.length - 1; i++) {
        drawSegment(ctx, stroke.points[i], stroke.points[i+1]);
    }
}

function setTransforms(ctx: CanvasRenderingContext2D, width: number, height: number) {
    const canvasWidth = width * window.devicePixelRatio;
    const canvasHeight = height * window.devicePixelRatio;
    ctx.canvas.width = canvasWidth;
    ctx.canvas.height = canvasHeight;
    ctx.canvas.style.width = `${width}px`;
    ctx.canvas.style.height = `${height}px`;
    ctx.resetTransform();
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
}

@customElement("cw-canvas")
export class CWCanvas extends LitElement {
    strokes: InkStroke[] = [];
    recognitions: RecognitionResult = {result: [], gesture_result: []};

    @property() width: number = 300;
    @property() height: number = 150;
    private _backgroundColor: string = "#FFF";

    @query("canvas") private canvas!: HTMLCanvasElement;
    private ctx!: CanvasRenderingContext2D;
    private redrawRequested: boolean = false;
    private recoTimer: number | null = null;

    static get styles() {
        return css`
            :host {
                display: block;
            }
            :host(.drawing) canvas {
                touch-action: none;
            }
        `;
    }

    get backgroundColor(): string {
        return this._backgroundColor;
    }
    set backgroundColor(color: string) {
        this._backgroundColor = color;
        this.requestRedraw();
    }

    protected render() {
        return html`
            <canvas style="width: ${this.width}px; height: ${this.height}px;"></canvas>
        `;
    }

    private requestRedraw() {
        if(!this.redrawRequested) {
            this.redrawRequested = true;
            requestAnimationFrame(() => {
                this.redraw();
                this.redrawRequested = false;
            });
        }
    }
    private redraw() {
        this.ctx.fillStyle = this.backgroundColor;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        for(let stroke of this.strokes) {
            drawStroke(this.ctx, stroke);
        }
    }

    clear() {
        this.strokes = [];
        this.requestRedraw();
        this.recognitions = {result: [], gesture_result: []};
    }

    protected firstUpdated() {
        this.ctx = this.canvas.getContext("2d", {
            desynchronized: true,
            alpha: false,
        })!;

        setTransforms(this.ctx, this.width, this.height);

        this.redraw();

        const getPoint = (e: PointerEvent): InkPoint => {
            const cr = this.canvas.getBoundingClientRect();
            return {
                x: e.clientX - cr.left,
                y: e.clientY - cr.top,
                timestamp: e.timeStamp,
            }
        }

        const pointers: {[id: string]: InkStroke} = {};
        interface Stats {
            moves: number;
            distance: number;
            pointerType: string;
        }
        const clickDetector: {[id: string]: Stats} = {};

        this.canvas.addEventListener("pointerdown", e => {
            if(e.pointerType === "pen" || e.ctrlKey) {
                // Prevent reco from firing while we're writing
                if(this.recoTimer) {
                    clearTimeout(this.recoTimer);
                }

                // Capture all points
                this.classList.add("drawing");
                this.canvas.setPointerCapture(e.pointerId);
                const p = getPoint(e);
                const stroke: InkStroke = {
                    points: [p],
                    color: [0,0,0,1],
                };
                pointers[e.pointerId] = stroke;
                this.strokes.push(stroke);
            }
            clickDetector[e.pointerId] = {
                pointerType: e.pointerType,
                moves: 0,
                distance: 0,
            };
        });
        this.canvas.addEventListener("pointermove", e => {
            let distance = 0;
            if(e.pointerId in pointers) {
                let points: InkPoint[] = pointers[e.pointerId].points;
                let prevPoint = points[points.length-1];
                (e as ChromePointerEvent).getCoalescedEvents().forEach(ce => {
                    const p = getPoint(ce);
                    pointers[e.pointerId].points.push(p);
                    drawSegment(this.ctx, prevPoint, p);

                    const dx = p.x - prevPoint.x;
                    const dy = p.y - prevPoint.y;
                    distance += Math.sqrt(dx*dx + dy*dy);
                    
                    prevPoint = p;
                });
            }
            if(e.pointerId in clickDetector) {
                clickDetector[e.pointerId].moves += 1;
                clickDetector[e.pointerId].distance += distance;
            }
        });
        this.canvas.addEventListener("pointerup", e => {
            if(e.pointerId in clickDetector) {
                const stats = clickDetector[e.pointerId];
                console.log(stats);
                if(stats.pointerType !== "pen" && stats.moves < 5) {
                    const ev = new CustomEvent('canvas-click');
                    this.dispatchEvent(ev);
                } else if(stats.pointerType === "pen" && stats.distance < 7 && this.strokes.length == 1) {
                    const ev = new CustomEvent('canvas-click');
                    this.dispatchEvent(ev);
                    this.strokes = [];
                    this.redraw();
                }
                delete clickDetector[e.pointerId];
            }
            if(e.pointerId in pointers) {
                this.classList.remove("drawing");
                delete pointers[e.pointerId];
                if(this.strokes.length > 0) {
                    this.recoTimer = setTimeout(() => {
                      recognize(this.strokes).then(results => {
                        this.recognitions = results;
                        console.log(results);
                        const ev = new CustomEvent('canvas-recognition', {
                          detail: results,
                        });
                        this.dispatchEvent(ev);
                      });
                    }, 1000) as any;
                }
            }
        });
        this.canvas.addEventListener("pointercancel", e => {
            if(e.pointerId in pointers) {
                this.classList.remove("drawing");
                delete pointers[e.pointerId];
            }
        })

        this.canvas.addEventListener("contextmenu", e => {
            e.preventDefault();
        });
    }
}
