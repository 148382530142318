import Crossword from "./Crossword";
import Selection from "./Selection";
import GuessMap from "./GuessMap";

export function getCrossword(year: number, month: number, day: number): Promise<Crossword> {
    const sYear = year.toString();
    const sMonth = month.toString().padStart(2, "0");
    const sDay = day.toString().padStart(2, "0");
    const url = `https://raw.githubusercontent.com/doshea/nyt_crosswords/master/${sYear}/${sMonth}/${sDay}.json`;
    return fetch(url).then(resp => {
        return resp.json();
    });
}

export function isCompleted(cw: Crossword, guesses: GuessMap): boolean {
    for(let i = 0; i < cw.grid.length; i++) {
        const val = cw.grid[i];
        if(val !== "." && !(i in guesses)) {
            return false;
        }
        if(val !== "." && val !== guesses[i]) {
            return false;
        }
    }
    return true;
}

export function isFilledIn(cw: Crossword, guesses: GuessMap): boolean {
    for(let i = 0; i < cw.grid.length; i++) {
        const val = cw.grid[i];
        if(val !== "." && !(i in guesses)) {
            return false;
        }
    }
    return true;
}

export function getClue(cw: Crossword, selection: Selection) {
    const clues = cw.clues[selection.direction];
    const prefix = `${selection.clue}.`;
    for(let clue of clues) {
        if(clue.startsWith(prefix)) {
            return clue;
        }
    }
    return "Clue not found :-("
}