import { LitElement, html, css, customElement, property } from "lit-element";

import isSunday from "date-fns/isSunday";
import isSaturday from "date-fns/isSaturday";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import isAfter from "date-fns/isAfter";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import format from "date-fns/format"
import { stateManager, Loadable, CrosswordState } from "../model";
import { getDateKey } from "../common";
import { ExtendedCrossword } from "../crossword/ClueMap";

function getPrevSunday(date: Date): Date {
    while(!isSunday(date)) {
        date = subDays(date, 1);
    }
    return date;
}
function getNextSaturday(date: Date): Date {
    while(!isSaturday(date)) {
        date = addDays(date, 1);
    }
    return date;
}

function getFullRange(date: Date): Date[][] {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const start = getPrevSunday(firstDay);
    const end = getNextSaturday(lastDayOfMonth(firstDay));

    const days = [];
    let snaker = start;
    while(!isAfter(snaker, end)) {
        days.push(snaker);
        snaker = addDays(snaker, 1);
    }
    return breakByWeek(days);
}

function breakByWeek(days: Date[]): Date[][] {
    const weeks = [];

    let ctr = 0;
    while(ctr < days.length) {
        let week: Date[] = [];
        for(let i = 0; i < 7; i++) {
            week.push(days[ctr]);
            ctr += 1;
        }
        weeks.push(week);
    }
    return weeks;
}

class CWPicker extends LitElement {
    @property() date: Date = new Date(2015, 0, 1);
    @property() progress: {[date: string]: CrosswordState} = {};
    @property() crosswords: {[date: string]: Loadable<ExtendedCrossword>} = {};

    constructor() {
        super();
        stateManager.subscribe(state => {
            this.progress = state.progress;
            this.crosswords = state.crosswords;
        });
    }

    private nextMonth() {
        this.date = addMonths(this.date, 1);
    }
    private prevMonth() {
        this.date = subMonths(this.date, 1);
    }
    private selectDate(date: Date) {
        const ev = new CustomEvent("date-select", {
            detail: date,
        });
        this.dispatchEvent(ev);
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                justify-content: center;
            }
            table {
                border-collapse: collapse;
            }
            td, th {
                width: 40px;
                height: 40px;
                text-align: center;
            }
            th {
                background-color: #F1F3F4;
            }
            th:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            th:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            .buttons {
                display: flex;
                align-items: center;
            }
            .buttons .date {
                flex-grow: 1;
                text-align: center;
            }
            #container {
                width: 400px;
                display: flex;
                flex-direction: column;
            }
            td .wrapper {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
            }
            td .wrapper[status="in-progress"] {
                background-color: #FEEFC3;
            }
            td .wrapper[status="error"] {
                background-color: #FAD2CF;
            }
            td .wrapper[status="complete"] {
                background-color: #CEEAD6;
            }
        `;
    }

    render() {
        const weeks = getFullRange(this.date);
        return html`
            <div id="container">
                <div class="buttons">
                    <mwc-icon-button icon="chevron_left" @click="${this.prevMonth}"></mwc-icon-button>
                    <div class="date">${format(this.date, "MMMM y")}</div>
                    <mwc-icon-button icon="chevron_right" @click="${this.nextMonth}"></mwc-icon-button>
                </div>
                
                <table>
                    <tr>
                        <th>S</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                    </tr>
                    ${weeks.map(week => html`
                        <tr>
                            ${week.map(date => this.renderCell(date))}
                        </tr>
                    `)}
                </table>
            </div>
        `;
    }
    renderCell(date: Date) {
        let status = this.getStatus(date);
        return html`
            <td @click="${() => this.selectDate(date)}">
                <div class="wrapper"
                    status="${status}">
                    ${date.getDate()}
                </div>
            </td>`;
    }
    private getStatus(date: Date): string {
        const key = getDateKey(date);
        if(!(key in this.progress)) {
            return "not-started";
        }
        const state: CrosswordState = this.progress[key];
        if(state.isDone) {
            return "complete";
        }
        const numGuesses = Object.keys(state.guesses).length;
        if(numGuesses > 0) {
            return "in-progress";
        }

        const cw: Loadable<ExtendedCrossword> | undefined = this.crosswords[key];
        if(cw && cw.state == "error") {
            return "error";
        }
        return "not-started";
    }
}

customElements.define("cw-picker", CWPicker);