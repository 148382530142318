import Crossword from "./Crossword";
import { cellToIndex } from "./Cell";

// ClueMap is a way for efficiently looking up the clues
// associated with a specific cell.
export interface ClueMap {
    down: {[index: number]: number};
    across: {[index: number]: number};
}

export interface ExtendedCrossword extends Crossword {
    clueMap: ClueMap;
}

export function extendCrossword(cw: Crossword): ExtendedCrossword {
    return {
        ...cw,
        clueMap: generateClueMap(cw),
    };
}

function generateClueMap(cw: Crossword): ClueMap {
    let down: {[index: number]: number} = {};
    let across: {[index: number]: number} = {};

    for(let row = 0; row < cw.size.rows; row++) {
        for(let col = 0; col < cw.size.cols; col++) {
            const index = cellToIndex(cw, {row, col});
            const isWall = cw.grid[index] === ".";
            const value = cw.gridnums[index];

            const prevInCol = (row === 0) ? 0 : down[cellToIndex(cw, {row: row-1, col})];
            down[index] = (prevInCol !== 0 && !isWall) ? prevInCol : value;

            const prevInRow = (col === 0) ? 0 : across[cellToIndex(cw, {row: row, col: col-1})];
            across[index] = (prevInRow !== 0 && !isWall) ? prevInRow : value;
        }
    }
    return {down, across};
}