import { LitElement, html, css, customElement, property } from "lit-element";

import Cell, { cellToIndex } from "../crossword/Cell";
import Selection, { toggleDirection } from "../crossword/Selection";
import GuessMap from "../crossword/GuessMap";
import { ExtendedCrossword } from "../crossword/ClueMap";
import {
  stateManager,
  getCurrentCrossword,
  setSelection,
  getCurrentProgress,
  Loadable,
  setGuesses,
} from "../model";

import "./cw-cell";

function range(max: number): number[] {
  const arr = [];
  for (let i = 0; i < max; i++) {
    arr.push(i);
  }
  return arr;
}

@customElement("cw-crossword")
export class CWCrossword extends LitElement {
  @property() crossword: Loadable<ExtendedCrossword> | null = null;
  @property() selection: Selection = { direction: "across", clue: 1 };
  @property() guesses: GuessMap = {};
  @property() showHints: boolean = false;

  constructor() {
    super();

    stateManager.subscribe((state) => {
      this.crossword = getCurrentCrossword(state);
      const progress = getCurrentProgress(state);
      this.selection = progress.selection;
      this.guesses = progress.guesses;
      this.showHints = state.showHints;
    });
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }
      #crossword {
        margin: 0 auto;
        padding: 16px;
        touch-action: none;
      }
      .row {
        display: flex;
      }
      .message {
        text-align: center;
        padding: 16px;
        color: rgba(0, 0, 0, 0.54);
      }
      cw-cell {
        border-right: none;
        border-bottom: none;
      }
      cw-cell:last-child {
        border-right: 1px solid #000;
      }
      .row:last-child cw-cell {
        border-bottom: 1px solid #000;
      }
    `;
  }

  private get crosswordValue(): ExtendedCrossword {
    if (!this.crossword || this.crossword.state !== "success") {
      throw new Error("Crossword not loaded");
    }
    return this.crossword.value;
  }

  private isSelected(cell: Cell): boolean {
    if (!this.crossword) {
      return false;
    }
    const index = cellToIndex(this.crosswordValue, cell);
    const clue = this.crosswordValue.clueMap[this.selection.direction][index];
    return this.selection.clue == clue;
  }

  private handleClick(cell: Cell) {
    const cw = this.crosswordValue;
    const index = cellToIndex(cw, cell);

    if (this.isSelected(cell)) {
      const newDir = toggleDirection(this.selection.direction);
      setSelection({
        direction: newDir,
        clue: cw.clueMap[newDir][index],
      });
    } else {
      setSelection({
        direction: this.selection.direction,
        clue: cw.clueMap[this.selection.direction][index],
      });
    }
  }

  private handleClearCell(cell: Cell) {
    const cw = this.crosswordValue;
    const index = cellToIndex(cw, cell);
    const newGuesses = { ...this.guesses };
    delete newGuesses[index];
    setGuesses(newGuesses);
  }

  private handleCellChange(cell: Cell, value: string) {
    const cw = this.crosswordValue;
    const index = cellToIndex(cw, cell);
    setGuesses({
      ...this.guesses,
      [index]: value,
    });
  }

  renderCell(cell: Cell) {
    const cw = this.crosswordValue;
    const index = cellToIndex(cw, cell);
    const value = cw.grid[index];
    const guess = this.guesses[index] || "";
    const clue = cw.gridnums[index] || null;
    const selected = this.isSelected(cell);

    return html`
      <cw-cell
        .value="${guess}"
        .actual="${value}"
        .clue="${clue}"
        .isWall="${value === "."}"
        ?selected="${selected}"
        ?showErrors="${this.showHints}"
        @cell-change="${(e: CustomEvent<string>) =>
          this.handleCellChange(cell, e.detail)}"
        @cell-clear="${() => this.handleClearCell(cell)}"
        @cell-click="${() => this.handleClick(cell)}"
      >
      </cw-cell>
    `;
  }
  render() {
    if (!this.crossword || this.crossword.state === "loading") {
      return html`<div class="message">Loading crossword...</div>`;
    }
    if (this.crossword.state === "error") {
      return html`<div class="message">Unable to load crossword.</div>`;
    }

    const cw: ExtendedCrossword = this.crossword.value;

    const width = cw.size.cols * 41 + 1;
    const height = cw.size.rows * 41 + 1;
    const style = `width: ${width}px; height: ${height}px`;

    return html`
      <div id="crossword" style="${style}">
        ${range(cw.size.rows).map(
          (rownum) => html`
            <div class="row">
              ${range(cw.size.cols).map((colnum) =>
                this.renderCell({ col: colnum, row: rownum })
              )}
            </div>
          `
        )}
      </div>
    `;
  }
}
