import { LitElement, html, customElement, css, property } from "lit-element";
import page from "page";

import "./cw-picker";
import "./cw-crossword";
import "@material/mwc-top-app-bar-fixed";
import "@material/mwc-icon-button";
import "@material/mwc-button";
import "@material/mwc-dialog";
import "@material/mwc-formfield";
import "@material/mwc-switch";

import { stateManager, AppState, Page, navigateTo, loadCrossword, getTitle, loadProgress, setHints, MessageState, MessageType, setMessageVisibility } from "../model";
import { getDateKey } from "../common";

@customElement("cw-app")
export class CWApp extends LitElement {
    @property() page: Page = {name: "main"};
    @property() title: string = "Crosswords";
    @property() settingsOpened: boolean = false;
    @property() message: MessageState = {visible: false, type: "CrosswordClose"};

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;

                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                user-select: none;
                font-family: 'Roboto', sans-serif;
            }
            section {
                display: none;
            }
            section[visible] {
                display: block;
            }
            [hidden] {
                display: none;
            }
            mwc-top-app-bar-fixed {
                --mdc-theme-primary: rgba(255,255,255,0.96);
                --mdc-theme-on-primary: #000;
            }
            #main {
                padding: 16px;
            }
            #main p {
                max-width: 600px;
                margin: 0 auto 32px auto;
            }
        `;
    }

    constructor() {
        super();

        stateManager.subscribe((state: AppState) => {
            this.page = state.page;
            this.title = getTitle(state);
            this.message = state.message;
            console.log(state);
        });

        loadProgress();

        page("/", () => {
            navigateTo({name: "main"});
        });
        page("/:date", (ctx) => {
            navigateTo({name: "crossword", date: ctx.params.date});
            loadCrossword(ctx.params.date);
        });
        page();
    }

    private handleSelectDate(e: CustomEvent<Date>) {
        const date = e.detail;
        page(`/${getDateKey(date)}`)
    }

    private goBack() {
        page("/");
    }
    private showSettings() {
        this.settingsOpened = true;
    }
    private settingsClosed() {
        this.settingsOpened = false;
    }
    private messagesClosed() {
        setMessageVisibility(false);
    }
    private onHintsChanged(e: Event) {
        let showHints = (e.target as HTMLInputElement).checked;
        setHints(showHints);
    }

    render() {
        return html`
            <mwc-top-app-bar-fixed centerTitle>
                <mwc-icon-button icon="arrow_back" slot="navigationIcon"
                    @click="${this.goBack}"
                    ?hidden="${this.page.name === "main"}">
                </mwc-icon-button>
                <div slot="title">${this.title}</div>
                <div slot="actionItems" ?hidden="${this.page.name === "main"}">
                    <mwc-icon-button icon="settings"
                    @click="${this.showSettings}">
                </div>

                <!-- content -->
                <section id="main" ?visible="${this.page.name === "main"}">
                    <p>
                        Welcome to Crosswords! Select a puzzle below to get started.
                        Write in answers with a stylus. Scribble over an answer or write a "-" to clear it. Use "?" to get some help.
                    </p>
                    <cw-picker @date-select="${this.handleSelectDate}"></cw-picker>
                </section>
                <section id="crossword" ?visible="${this.page.name === "crossword"}">
                    <cw-crossword></cw-crossword>
                </section>
            </mwc-top-app-bar-fixed>

            <!-- settings -->
            <mwc-dialog title="Settings" ?open="${this.settingsOpened}" @closed="${this.settingsClosed}">
                <div>
                    <mwc-formfield label="Show mistakes">
                        <mwc-switch @change="${this.onHintsChanged}"</mwc-switch>
                    </mwc-formfield>
                </div>
                <mwc-button
                    slot="primaryAction"
                    dialogAction="accept">
                    Done
                </mwc-button>
            </mwc-dialog>

            <!-- messages -->
            <mwc-dialog ?open="${this.message.visible}" @closed="${this.messagesClosed}">
                <div>
                    ${this.getMessage(this.message.type)}
                </div>
                <mwc-button
                    slot="primaryAction"
                    dialogAction="accept">
                    Done
                </mwc-button>
            </mwc-dialog>
        `;
    }
    private getMessage(type: MessageType) {
        if(type === "CrosswordClose") {
            return "You're close, but there are some mistakes";
        }
        if(type === "CrosswordComplete") {
            return "Congratulations! You've completed the crossword.";
        }
        return "This shouldn't appear...";
    }
}